<script>
import DesktopBackButton from "@/components/DesktopBackButton.vue";
import { mapGetters } from "vuex";
import StaffDetailsCard from "@/components/StaffDetailsCard.vue";
import searchDebounce from "@/mixins/searchDebounce";
import UnsyncedApps from "@/components/UnsyncedApps.vue";
import { UI_ACTIONS } from "@/utils/constants";

export default {
  name: "VerifyAccess",
  components: { UnsyncedApps, StaffDetailsCard, DesktopBackButton },
  props: {
    staffId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loaded: false,
      defaultBack: null,
      inputValue: "",
      showTooltip: false,
      isExternalStaff: this.$store.getters.isExternalStaff(
        this.locationId,
        this.staffId
      ),
      uIActions: UI_ACTIONS
    };
  },
  mixins: [searchDebounce],
  created: async function() {
    this.$store.commit("setAppFilter", "");

    if (this.unSyncedApps?.length === 0) {
      console.log("Before calling fetchUnSyncedAppsForStaffAtLocation from VerifyAccess: locType-"+this.locationType);
      await this.$store.dispatch("fetchUnSyncedAppsForStaffAtLocation", {
        locationId: this.locationId,
        locType: this.locationType,
        staffId: this.staffId
      });
    }
    console.log("Current unSyncedApps: " + JSON.stringify(this.unSyncedApps));
    this.loaded = true;
    window.scroll(0, 0);
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      fnActionByName: "getActionByName"
    }),
    staff: function() {
      return this.$store.getters.staff(this.locationId, this.staffId);
    },
    locationType: function() {
        let locType = this.$store.state.locationsModule.locations.find(l => l.id === this.locationId).locType;
        console.log("LocationType evaluated from VerifyAccess: locType-"+locType);
        return locType;
    },
    unSyncedApps: function() {
      console.log("Called get unSyncedApps for the locationId:" + this.locationId);
      let locType = this.locationId.includes('NG') ? 'NonGovLocation' : 'GovLocation';
      let locationIdWithoutNG = String(this.locationId).replace("NG", "");
      return this.$store.getters.unSyncedApps(parseInt(locationIdWithoutNG), locType, this.staffId);
    },
    hasUnSyncedApps() {
      return this.unSyncedApps?.length > 0;
    }
  },
  methods: {
    repairAccess() {
      this.$store.dispatch("repairAccess");
    }
  }
};
</script>

<template>
  <v-container
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
      page: $vuetify.breakpoint.mdAndUp
    }"
  >
    <DesktopBackButton v-if="$vuetify.breakpoint.mdAndUp" class="mt-2" />
    <div
      v-if="loaded && staff && staff.detuserid"
      :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      class="d-flex mt-3"
    >
      <!-- Staff details card -->
      <div class="flex-shrink-1 order-md-2 ml-1">
        <StaffDetailsCard
          :disable-verify-access="true"
          :location-id="locationId"
          :staff="staff"
          :staff-id="staffId"
        />
      </div>

      <!-- Apps list -->
      <div class="flex-grow-1 order-md-1">
        <div :class="{ 'mt-3': $vuetify.breakpoint.smAndDown }">
          <v-card class="msa-list">
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <v-text-field
                id="staff-apps--search-field"
                aria-label="filter applications list"
                class="data-table--search-field"
                hide-details
                label=""
                outlined
                placeholder="Search app"
                prepend-inner-icon="search"
                @input="onSearchChangeDebounced"
              />
            </div>
            <div
              v-if="
                hasUnSyncedApps && fnActionByName(uIActions.LIST_APPLICATIONS)
              "
            >
              <UnsyncedApps
                :accordion="false"
                :apps="unSyncedApps"
                app-heading="Applications"
              />
            </div>
            <div v-else>
              <div class="pl-4 pt-4 pb-2">
                <p v-if="!hasUnSyncedApps">
                  No Unsynchronized Application Found.
                </p>
                <p v-else>
                  User has no access to application list.
                </p>
              </div>
            </div>
          </v-card>

          <v-row
            v-if="
              hasUnSyncedApps && fnActionByName(uIActions.LIST_APPLICATIONS)
            "
            class="mt-4 ml-0"
            justify="start"
          >
            <v-btn
              v-if="unSyncedApps.length > 0"
              aria-label="Repair Access"
              class="primary pt-0 pb-0 pl-4 pr-4"
              @click="repairAccess"
            >
              Repair Access
            </v-btn>
            <button
              id="accessRepair"
              aria-describedby="accessRepairContent"
              class="pa-2"
              @blur="showTooltip = false"
              @focus="showTooltip = true"
              @mouseleave="showTooltip = false"
              @mouseover="showTooltip = true"
            >
              <v-icon
                :color="$vuetify.theme.dark ? '#7EA0E4' : '#2E5299'"
                :size="28"
                alt="information"
              >
                mdi-information
              </v-icon>
            </button>
            <v-tooltip v-model="showTooltip" attach="#accessRepair">
              <p id="accessRepairContent" aria-hidden="true" role="tooltip">
                Note: The 'Repair Access' function will trigger the provisioning
                or deprovisioning of the tasks listed above, based on their
                status in the MSA.<br />
                After approximately 5 minutes of successful repair, return to
                this page; you will notice that no applications are listed.
              </p>
            </v-tooltip>
          </v-row>
        </div>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped></style>
