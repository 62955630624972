var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    mobile: _vm.$vuetify.breakpoint.smAndDown,
    page: _vm.$vuetify.breakpoint.mdAndUp
  }},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('DesktopBackButton',{staticClass:"mt-2"}):_vm._e(),(_vm.loaded && _vm.staff && _vm.staff.detuserid)?_c('div',{staticClass:"d-flex mt-3",class:{ 'flex-column': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"flex-shrink-1 order-md-2 ml-1"},[_c('StaffDetailsCard',{attrs:{"disable-verify-access":true,"location-id":_vm.locationId,"staff":_vm.staff,"staff-id":_vm.staffId}})],1),_c('div',{staticClass:"flex-grow-1 order-md-1"},[_c('div',{class:{ 'mt-3': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-card',{staticClass:"msa-list"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('v-text-field',{staticClass:"data-table--search-field",attrs:{"id":"staff-apps--search-field","aria-label":"filter applications list","hide-details":"","label":"","outlined":"","placeholder":"Search app","prepend-inner-icon":"search"},on:{"input":_vm.onSearchChangeDebounced}})],1):_vm._e(),(
              _vm.hasUnSyncedApps && _vm.fnActionByName(_vm.uIActions.LIST_APPLICATIONS)
            )?_c('div',[_c('UnsyncedApps',{attrs:{"accordion":false,"apps":_vm.unSyncedApps,"app-heading":"Applications"}})],1):_c('div',[_c('div',{staticClass:"pl-4 pt-4 pb-2"},[(!_vm.hasUnSyncedApps)?_c('p',[_vm._v(" No Unsynchronized Application Found. ")]):_c('p',[_vm._v(" User has no access to application list. ")])])])]),(
            _vm.hasUnSyncedApps && _vm.fnActionByName(_vm.uIActions.LIST_APPLICATIONS)
          )?_c('v-row',{staticClass:"mt-4 ml-0",attrs:{"justify":"start"}},[(_vm.unSyncedApps.length > 0)?_c('v-btn',{staticClass:"primary pt-0 pb-0 pl-4 pr-4",attrs:{"aria-label":"Repair Access"},on:{"click":_vm.repairAccess}},[_vm._v(" Repair Access ")]):_vm._e(),_c('button',{staticClass:"pa-2",attrs:{"id":"accessRepair","aria-describedby":"accessRepairContent"},on:{"blur":function($event){_vm.showTooltip = false},"focus":function($event){_vm.showTooltip = true},"mouseleave":function($event){_vm.showTooltip = false},"mouseover":function($event){_vm.showTooltip = true}}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '#7EA0E4' : '#2E5299',"size":28,"alt":"information"}},[_vm._v(" mdi-information ")])],1),_c('v-tooltip',{attrs:{"attach":"#accessRepair"},model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_c('p',{attrs:{"id":"accessRepairContent","aria-hidden":"true","role":"tooltip"}},[_vm._v(" Note: The 'Repair Access' function will trigger the provisioning or deprovisioning of the tasks listed above, based on their status in the MSA."),_c('br'),_vm._v(" After approximately 5 minutes of successful repair, return to this page; you will notice that no applications are listed. ")])])],1):_vm._e()],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }