<script>

export default {
  name: "DualLightsIndicator",
  props: {
    leftStatus: {
      type: Boolean,
      required: true
    },
    rightStatus: {
      type: Boolean,
      required: true
    },
    systems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showLeftTooltip: false,
      showRightTooltip: false
    }
  },
  computed: {
    systemNames: function () {
      const length = this.systems.length;
      const formats = {
        0: 'App controls',
        1: this.systems[0],
        2: `${this.systems[0]} and ${this.systems[1]}`,
        3: `${this.systems.slice(0, -1).join(', ')} and ${this.systems[length - 1]}`,
      };
      return formats[length] ?? 'App controls';
    },
  }
}
</script>

<template>
  <div class="lights-container d-flex align-center justify-center">
    <button
      id="msaStatus"
      aria-label="msa sync status"
      class="light left-light"
      :class="leftStatus ? 'green-light-left' : 'orange-light-left'"
      aria-describedby="msaStatusTooltip"
      @blur="showLeftTooltip = false"
      @focus="showLeftTooltip = true"
      @mouseover="showLeftTooltip = true"
      @mouseleave="showLeftTooltip = false"
    >
      MSA
      <v-tooltip
        v-model="showLeftTooltip"
        :attach="true"
      >
        <p
          id="msaStatusTooltip"
          role="tooltip"
          aria-hidden="true"
        >
          {{ leftStatus ? 'Provisioned in MSA' : 'Not provisioned in MSA' }}
        </p>
      </v-tooltip>
    </button>
    <button
      id="appStatus"
      aria-label="app sync status"
      class="light right-light"
      :class="rightStatus ? 'green-light-right' : 'orange-light-right'"
      aria-describedby="appStatusTooltip"
      @blur="showRightTooltip = false"
      @focus="showRightTooltip = true"
      @mouseover="showRightTooltip = true"
      @mouseleave="showRightTooltip = false"
    >
      App
      <v-tooltip
        v-model="showRightTooltip"
        :attach="true"
      >
        <p
          id="appStatusTooltip"
          role="tooltip"
          aria-hidden="true"
        >
          {{ rightStatus ? `Provisioned in ${systemNames}` : `Not provisioned in ${systemNames}` }}
        </p>
      </v-tooltip>
    </button>
  </div>
</template>

<style scoped lang="scss">
.lights-container {
  width: 3rem;
  height: 1.5rem;
  padding: 2px;

  .light {
    position: relative;
    color: white;
    height: 1.75rem;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.75rem;
    border: 1px solid;
    box-sizing: border-box;
  }

  .left-light {
    padding-left: 1rem;
    padding-right: 10px;
    border-top-left-radius: 99999rem;
    border-bottom-left-radius: 99999rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  }

  .right-light {
    padding-right: 1rem;
    padding-left: 10px;
    border-top-right-radius: 99999rem;
    border-bottom-right-radius: 99999rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15);
  }
  .green-light-left {
    border-color: #CBEDFD;
    background: radial-gradient(45.83% 66.67% at 29.69% 33.33%, rgba(251, 251, 251, 0.6) 0%, rgba(0, 170, 69, 0.6) 100%), linear-gradient(90deg, #00AA45 61.97%, #7EA0E4 96.87%);
  }
  .orange-light-left {
    border-color: #FFB8C1;
    background: radial-gradient(45.83% 66.67% at 29.69% 33.33%, rgba(251, 251, 251, 0.6) 0%, rgba(243, 99, 27, 0.6) 100%), linear-gradient(90deg, #F3631B 61.97%, #E47E7E 96.87%);
  }
  .green-light-right {
    border-color: #CBEDFD;
    background: radial-gradient(41.67% 72.22% at 70.31% 37.5%, rgba(255, 255, 255, 0.6) 0%, rgba(0, 170, 69, 0.6) 100%), linear-gradient(270deg, #00AA45 77.71%, #7EA0E4 96.87%);
  }
  .orange-light-right {
    border-color: #FFB8C1;
    background: radial-gradient(45.83% 69.44% at 73.44% 37.5%, rgba(255, 255, 255, 0.6) 0%, rgba(243, 99, 27, 0.6) 100%), linear-gradient(270deg, #F3631B 83.53%, #D7153A 96.87%);
  }
}


</style>