<script>

import AppIcon from "@nswdoe/doe-ui-core/src/AppIcon/AppIcon.vue";
import DualLightsIndicator from "@/components/DualLightsIndicator.vue";

export default {
    name: "UnsyncedApps",
    components: {DualLightsIndicator, AppIcon},
    props: {
        apps: {
            type: Array,
            required: true,
            default: () => []
        },
        accordion: {
            type: Boolean,
            default: false
        },
        focusable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
          appStatus: false,
          activePanels: [],
        };
    },
    computed: {
      displayApps() {
        const _apps = [...this.apps];
        return _apps.map(app => {
          return {
            ...app,
            appStatus: !app.taskAccessVerifications.some((task) => task.msaAccessState !== task.controlAccessState)
          }
        })
      }
    },
    methods: {
    }
}
</script>

<template>
  <div v-if="displayApps && displayApps.length > 0">
    <div
      class="list-header"
    >
      <slot name="AppHeader">
        <h2>Unsynced Applications</h2>
      </slot>
    </div>
    <v-expansion-panels
      v-if="displayApps.length > 0"
      v-model="activePanels"
      :accordion="accordion"
      :focusable="focusable"
      multiple
    >
      <v-expansion-panel
        class="unsynced-apps"
        v-for="(app,i) in displayApps"
        :key="i"
      >
        <v-expansion-panel-header
          class="panel"
        >
          <v-row>
            <v-col class="col-9">
              <div class="d-flex align-center">
                <AppIcon
                  :app-name="app.icon"
                  class="mr-3"
                />
                <span>{{ app.applicationDesc }}</span>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="unsynced-tasks"
          v-for="(task, j) in app.taskAccessVerifications"
          :key="j"
        >
          <v-row class="mt-3 pl-12 pr-12">
            <v-col class="col-8 col-md-9">
              <div class="d-flex align-center">                  
                <span>{{ task.taskName }}</span>
              </div>
            </v-col>
            <v-col
              class="col-4 col-md-3 pl-10 pl-md-5 d-flex align-center"
            >
              <DualLightsIndicator
                :systems="task.systems"
                :left-status="task.msaAccessState"
                :right-status="task.controlAccessState"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style scoped lang="scss">
.unsynced-apps {
  margin-top: 0;
}
.unsynced-tasks {
  border-bottom: 1px solid $ads-light-20;
}
.list-header {
  padding: 1rem 1.5rem;
  font-weight: 600;
  background-color: #CFD8DC;
}

.theme--dark {
  .list-header {
    background-color: #263238;
  }
}

::v-deep {
  .v-list {
    padding: 0;
  }

  .v-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .theme--dark {
    .v-list-item {
      border-bottom: 1px solid #6e6e6e;
    }
  }
}
</style>